import CancelablePromise from '@nextgis/cancelable-promise';
import { createHttp } from '../../@nextgis_misc/services/Http';
// import { notice } from '@nextgis_misc/vuetify/notice';
// import authApi from '../api/authApi';
// import { i18n } from '../plugins/i18n';
import settings from '../settings';
import { handleApiError } from '../utils/handleApiError';
import errorService from './errorService';

export const http = createHttp(settings.baseUrl, {
  crossDomain: true,
  onError: (er) => {
    if (er instanceof CancelablePromise.CancelError) {
      return;
    }
    if (er && er.status) {
      if (er.status === 401) {
        // return authApi.logout();
      } else if (er.status === 403) {
        // return notice(i18n.t('http_forbidden_error'), {
        //   color: 'error',
        //   timeout: 3000,
        // });
      }
    }
    errorService.showError(handleApiError(er).join(';'))
    // notice(handleApiError(er).join(';'), { color: 'error', timeout: 3000 });
  },
});
