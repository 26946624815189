import prettier, { Plugin } from 'prettier';

export default class Formatter {
  plugins: (string | Plugin<any>)[] = [];

  // constructor() {
  // }

  format(content: string) {
    return prettier.format(content, { plugins: this.plugins });
  }
}