import { SrsApi } from "@/srs/services/SrsApi";
import { Ref, ref, watchEffect, computed, watch } from "vue";
import { useStore } from 'vuex';
import type { Srs } from '../srs/interfaces/Srs';
import { SrsDefinition } from "../srs/interfaces/SrsDefintion";


const useSrsDetailed = (id: Ref<null | number>) => {
  const store = useStore()
  const srsData: Ref<Srs|undefined> = ref(undefined);
  const srsDefinitions: Ref<SrsDefinition[]> = computed(
    () => srsData.value ? SrsApi.getDefinitions(srsData.value):[]
  );
  const isBookmarked: Ref<boolean> = ref(false);

  const checkIsBookmarked = (srsId:number) => {
    const result = store.state.srs.bookmarks.filter((item:Partial<Srs>) => item.id === srsId);
    return !!result.length;
  };

  watchEffect(async () => {
    if (id.value) { 
      srsData.value = await SrsApi.get(id.value)
      isBookmarked.value = checkIsBookmarked(srsData.value.id);
    }
  });

  watch(store.state.srs.bookmarks, () => {
    if (srsData.value) {
      isBookmarked.value = checkIsBookmarked(srsData.value.id);
    }
  });

  return { srsData, srsDefinitions, isBookmarked };
}

export default useSrsDetailed;