import { full, defined, DeepPartial, isObjKey, sleep } from '@nextgis/utils';
import CancelablePromise from '@nextgis/cancelable-promise';
// import { createGeoJsonFeature } from '@nextgis/ngw-kit';
import { http } from '../../services/Http';
import { PaginationRequestOptions } from '../../options/PaginationRequestOptions';
// import { srsModule } from '../store/srsModule';

// import type { FeatureItem } from '@nextgis/ngw-connector';
import type { SrsItemsQueryParams } from '../../interfaces/SrsItemsQueryParams';
// import type { AdmDivisionFeature } from '../../interfaces/AdmDivisionFeature';
// import type { MainMetadata } from '../../interfaces/MainMetadata';
// import type { AdmDivision } from '../../interfaces/AdmDivision';
// import type { ExtractWkt } from '../../interfaces/ExtractWkt';
import type { Srs } from '../interfaces/Srs';
import { SrsDefinition, SrsDefinitionId, SrsDefinitionLabel } from "../interfaces/SrsDefintion";

const PAGINATION_LIMIT = 30;

const requestHelper = CancelablePromise.createControl();

const api = 'api/v1';
const baseUrl = `/${api}`;
const srs = `${baseUrl}/spatial_ref_sys`;

export class SrsApi {
  static get(id: number | string): CancelablePromise<Srs> {
    return http.get(`${srs}/${id}`);
  }

  static all(): Promise<Srs[]> {
    return this.find();
  }

  static find(
    params: SrsItemsQueryParams = {},
    opt: PaginationRequestOptions = {}
  ): CancelablePromise<Srs[]> {
    const p: Record<string, any> = {};
    Object.keys(params).forEach((key) => {
      if (isObjKey(params, key)) {
        const val = params[key];
        if (full(val)) {
          p[key] = val;
        }
      }
    });
    const limit = opt.limit || PAGINATION_LIMIT;
    if (limit !== Infinity) {
      p.limit = limit;
    }
    const { skip } = opt;
    if (skip) {
      p.skip = skip;
    }
    const props = new URLSearchParams(p).toString();
    return http.get(`${srs}/${props ? `?${props}` : ''}`);
  }

  static getDefinitions(srsData: Srs): SrsDefinition[] {
    return Object.values(SrsDefinitionId)
      .filter((defId) => srsData[defId])
      .map((defId) => ({
         id: defId,
         label: SrsDefinitionLabel[defId],
         content: srsData[defId] 
      }));
  }

  // static async save(opt: DeepPartial<Srs>): Promise<[Srs, boolean]> {
  //   const { id, ...body } = opt;
  //   const created = !defined(id);
  //   let snapshot: Srs;
  //   if (created) {
  //     snapshot = await http.post(`${srs}/`, { body });
  //   } else {
  //     snapshot = await http.patch(`${srs}/${id}`, { body });
  //   }
  //   // srsModule.appendSrs(snapshot);
  //   // remove all loaded srs items to re-query the catalog list with the correct sort
  //   srsModule.setSrsItems([]);
  //   return [snapshot, created];
  // }

  // static insert(body: DeepPartial<Srs[]>) {
  //   return http.post(`${srs}/`, { body });
  // }

  // static async remove(id: string | number): Promise<void> {
  //   await http.delete(`${srs}/${id}`);
  //   srsModule.removeSrs(Number(id));
  // }

  // static async download(
  //   id: string | number,
  //   format: SrsDefinitionId = 'wkt'
  // ): Promise<any> {
  //   return http.get(`${srs}/${id}/download?format=${format}`);
  // }

  // static getFieldLabel(field: string, meta?: MainMetadata | null): string {
  //   if (meta) {
  //     const actions = meta.properties;
  //     const { title } = actions[field];
  //     return title;
  //   }
  //   return field;
  // }

  // static wkt(file: File): Promise<ExtractWkt> {
  //   return http.post(`${srs}/file/wkt/`, { body: { data_file: file } });
  // }

  // static async fetchDivisions(
  //   oktmoParent: string | number
  // ): Promise<Pick<AdmDivision, 'oktmo_p' | 'oktmo' | 'NAME' | 'ADMIN_LVL'>[]> {
  //   // await sleep(300);
  //   // const resp = (await import('./mockDivisions.json')) as any;
  //   // return resp.default.map((x: FeatureItem) => x.fields);
  //   const query = `?geom=no&fld_oktmo_p=${oktmoParent}&fields=oktmo_p,oktmo,NAME,ADMIN_LVL&extensions=`;
  //   return http.get(baseUrl + '/adm_division/' + query).then((resp) => {
  //     return resp.map((x: FeatureItem) => x.fields);
  //   });
  // }

  // static async fetchDivision(
  //   oktmo: string | number
  // ): Promise<AdmDivisionFeature | undefined> {
  //   // const resp = (await import('./mockDivision.json')).default as any;
  //   const query = `?limit=1&srs=4326&geom_format=geojson&fld_oktmo=${oktmo}&extensions=`;
  //   const resp = await http.get(baseUrl + '/adm_division/' + query);
  //   return resp.length ? createGeoJsonFeature(resp[0]) : undefined;
  // }

  // @requestHelper.WaitForMe()
  // static metadata(): CancelablePromise {
  //   if (srsModule.meta) {
  //     return CancelablePromise.resolve(srsModule.meta);
  //   }
  //   return http.get(`${baseUrl}/openapi.json`).then((metadata) => {
  //     return srsModule.setSrsMetadata(metadata.components.schemas).then(() => {
  //       return srsModule.meta;
  //     });
  //   });
  // }
}
