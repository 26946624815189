import { CodeSyntaxId } from "@/interfaces/CodeSyntaxId";
import JsFormatter from "./JsFormatter";
import HtmlFormatter from "./HtmlFormatter";
import YamlFormatter from "./YamlFormatter";
import TextFormatter from "./TextFormatter";

const formatterFactory = {
  create(syntax: CodeSyntaxId) {
    if (syntax === 'js') return new JsFormatter();
    if (syntax === 'html') return new HtmlFormatter();
    if (syntax === 'yaml') return new YamlFormatter();
    return new TextFormatter();
  }
};

export default formatterFactory;