import Notify from 'quasar/src/plugins/Notify.js';;

const showError = (e: string) => {
  Notify.create({
    message: e,
    color: 'negative',
    actions: [{ icon: 'mdi-close', color: 'white' }]
  })
}

export default { showError };