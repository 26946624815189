import { geoJSON, latLng, LatLng } from 'leaflet';
import { MultiPolygon } from 'geojson';

const latLngPointToGeojson = (point: LatLng) => {
  const wrappedLatLng = latLng(point).wrap();
  return {
    type: 'Point',
    coordinates: [wrappedLatLng.lng, wrappedLatLng.lat],
  };
};

const getGeojsonBounds = (data: MultiPolygon) => geoJSON(data).getBounds();

export default { latLngPointToGeojson, getGeojsonBounds }