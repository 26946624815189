import { ref, Ref, computed } from 'vue';
import { CodeSyntaxId } from '@/interfaces/CodeSyntaxId';
import formatterFactory from '@/utils/formatter/formatterFactory';

const useFormatter = (content: Ref<string>, syntax: Ref<CodeSyntaxId>) => {
  const formatter = computed(() => formatterFactory.create(syntax.value));
  const contentFormatted = computed(() => formatter.value.format(content.value));
  return { contentFormatted, formatter };
};

export default useFormatter;
