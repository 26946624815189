export enum SrsDefinitionId {
  wkt = "wkt",
  proj = "proj",
  prj = "prj",
}
export enum SrsDefinitionLabel {
  wkt = "WKT",
  proj = "Proj",
  prj = "Prj",
}

export interface SrsDefinition {
  id: SrsDefinitionId.wkt | SrsDefinitionId.proj | SrsDefinitionId.prj;
  label: string;
  content: string;
}
