import { ApiError, ApiErrorItem } from '../interfaces/ApiError';
// import { i18n } from '../plugins/i18n';

function getErrorMessage(er: ApiErrorItem) {
  if (er.type === 'value_error.missing') {
    return `${er.msg} '${er.loc[1]}'`;
  }
  return er.msg;
}

export function handleApiError(er: ApiError): string[] {
  const detail = er.detail || er.message;
  let errors: string[];
  
  if (detail) {
    errors =  typeof detail === 'string' ? [detail] : detail.map(getErrorMessage)
  } else {
    errors = []
  }
  return errors.map((x) => String(x));
  //  return errors.map((x) => String(i18n.t(x)));
}

